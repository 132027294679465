import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface DropDownProps {
  options: Array<{ value: string; label: string }>; 
  placeholder?: string; 
  value: string;
  required?: boolean 
  onChange: (event: SelectChangeEvent) => void; 
}

const DropDown: React.FC<DropDownProps> = ({
  options,
  placeholder ,
  value,
  required= false,
  onChange,
}) => {
  return (
    <Box sx={{ height: 64 }} >
      <FormControl fullWidth variant="outlined">
        <Select
          required={required}
          value={value}
          onChange={onChange}
          displayEmpty
          renderValue={(selected) => selected || placeholder}
          sx={{
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "ring-blue-500", 
            },
            "& .MuiSelect-select": {
              padding: "16px 14px",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropDown;

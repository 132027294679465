// store/timerSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TimerState {
  timeLeft: number;
}

const initialState: TimerState = {
  timeLeft: 300, // Default 5 minutes
};

const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setTimeLeft: (state, action: PayloadAction<number>) => {
      state.timeLeft = action.payload;
    },
    decrementTime: (state) => {
      state.timeLeft = Math.max(state.timeLeft - 1, 0);
    },
  },
});

export const { setTimeLeft, decrementTime } = timerSlice.actions;
export default timerSlice.reducer;

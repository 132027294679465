import React from "react";
import TextField from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material/styles";

interface CustomTextFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  required?: boolean
  sx?: SxProps<Theme>; // Allow custom styling through sx prop
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  value,
  onChange,
  placeholder = "",
  required = false,
  sx = {},
}) => {
  return (
    <TextField
      required= {required}
      variant="outlined"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      sx={{
        "& .MuiOutlinedInput-root": {
          height: 90,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "ring-blue-500", 
          },
        },
        
        ...sx, 
      }}
    />
  );
};

export default CustomTextField;

// src/App.tsx
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PaymentHomeScreen from './pages/PaymentHomeSceen';
import PaymentSummary from './pages/PaymentSummary';
import NotFound from './pages/NotFound';
import PaymentFailedCVV from './pages/PaymentFailedCVV';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsandService from './pages/TermsandService';
import PaymentLinkExpired from './pages/PaymentLinkExpired';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/payment-booking" replace />} />
      <Route path="/payment-booking" element={<PaymentHomeScreen />} />
      <Route path="/payment-summary" element={<PaymentSummary />} />
      <Route path="payment-failed-CVV" element={<PaymentFailedCVV />} />
      <Route path="payment-link-expired" element={<PaymentLinkExpired />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="terms-and-service" element={<TermsandService />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;

import React from 'react';
import timeout from '../assets/timeout.png'
import Footer from '../components/common/Footer';

interface PaymentTimeExp {
  payment_timestamp:string;
}

const PaymentFailedTimeExp: React.FC<PaymentTimeExp> = ( {payment_timestamp} ) => {

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex flex-col justify-center items-center gap-3 px-6 pt-6'>
        <div>
          <img src={timeout} alt='success-img' className='w-auto  h-auto object-contain' />
        </div>
        <div className='flex flex-col items-center gap-1'>
          <div className='text-[14px] font-normal leading-[21px]'>Payment Time Expired</div>
          <div className='text-[24px] font-bold leading-[28px]'>Payment Time Expired</div>
          <div className='text-[14px] font-normal leading-[21px]'>{payment_timestamp}</div>
        </div>
        <div className='flex flex-col text-center font-normal text-[14px] leading-[21px] font-gantari text-[#D32F2F]'>
          Your payment time has expired,<br></br>
          Please contact the service provider to retry.
        </div>
      </div>
      <div className='p-6'>
        <Footer />
      </div>
    </div>
  );
};

export default PaymentFailedTimeExp;

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import error from '../assets/404error.png'

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/dashboard');
  };

  return (
    <div className='min-h-screen bg-gradient-to-b from-white to-[#DBF2FF]'>
      <div className='p-[24px] md:p-[80px] flex flex-col-reverse md:flex md:flex-col gap-[40px]'>
        <div className='flex flex-col gap-[40px]'>
          <div className='flex flex-col gap-[12px]'>
            <div className='text-[#019DF766] text-[128px] md:text-[300px] font-bold leading-[153.4px] md:leading-[360px]'>
              404
            </div>
            <div className='text-[#3E4044] text-[32px] md:text-[64px] font-semibold leading-[38.5px] md:leading-[77px]'>
              Oops!
            </div>
            <div className='text-[#3E4044] text-[20px] md:text-[32px] font-normal leading-[24px] md:leading-[40px]'>
              Sorry, we can’t find the page you’re looking for.
            </div>
          </div>
          <button className='py-[16px] px-[32px] text-white text-center bg-[#019DF7] rounded max-w-[275px]'>
            Go to Home Page
          </button>
        </div>
        <div className='md:flex md:flex-col md:items-end items-start mt-[160px] md:mt-[0px]'>
          <img
            src={error}
            alt='404error'
            className='max-w-full w-auto max-h-[120px] md:max-h-[300px] object-contain'
          />
        </div>
      </div>
    </div>
  );
}

import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import html2pdf from 'html2pdf.js';
import PaymentCard from '../components/common/PaymentCard';

interface PaymentReceiptProps {
  booking_id: number;
  amount: number;
  transaction_id: string;
  name: string;
  payment_reference: string;
  payment_type: string;
  payment_timestamp:string;
}

const PaymentReceipt = forwardRef((props: PaymentReceiptProps, ref) => {
  
  const paymentCardRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    handleDownload: () => {
      const element = paymentCardRef.current;
      if (!element) return;

      element.style.display = 'block'; // Ensure element is visible before generating the PDF

      const options = {
        margin: [0, 0, 0, 0],
        filename: 'payment-receipt.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true },
        jsPDF: {
          unit: 'pt',
          format: 'letter',
          orientation: 'portrait',
        },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      };

      html2pdf()
        .from(element)
        .set(options)
        .save()
        .finally(() => {
          element.style.display = 'none'; // Hide the element again after generating the PDF
        });
    },
  }));

  return (
    <div ref={paymentCardRef} style={{ display: 'none' }}>
      <PaymentCard
        bookingId={props.booking_id}
        totalAmount={props.amount}
        dateTime="2024-11-26"
        bookingDateTime="2024-11-25 18:30"
        name={props.name}
        transactionId={props.transaction_id}
        paymentType={props.payment_type}
        paymentRef={props.payment_reference}
        paymentTimeStamp={props.payment_timestamp}
      />
    </div>
  );
});

export default PaymentReceipt;

// Loader.tsx
import React from 'react';

const Loader: React.FC = () => {
    return (
        <div className="flex flex-col justify-center items-center ">
            <div className="loader"></div>
            <span className="ml-2">Loading...</span>
            <style>
                {`
                .loader {
                    border: 4px solid rgba(0, 0, 0, 0.1);
                    border-radius: 50%;
                    border-top: 4px solid #3498db;
                    width: 40px;
                    height: 40px;
                    animation: spin 1s linear infinite;
                }

                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                `}
            </style>
        </div>
    );
};

export default Loader;

import React, { useRef } from 'react';
import success from '../assets/success.png';
import Footer from '../components/common/Footer';
import PaymentReceipt from './PaymentReceipt';
import { useGlobalContext } from '../context/BookingContext';
import html2pdf from 'html2pdf.js';

interface PaymentDetail {
  label: string;
  value: any;
}

interface Paid {
  booking_id: number;
  amount: number;
  transaction_id: string;
  name: string;
  payment_reference: string;
  payment_type: string;
  payment_timestamp:string;
}

const Paid: React.FC<Paid> = ({ booking_id, amount, transaction_id, name, payment_reference, payment_type, payment_timestamp }) => {
  const receiptRef = useRef<any>(null);

  const handleDownload = () => {
    if (receiptRef.current) {
      receiptRef.current.handleDownload();
    }
  };

  const paymentDetails: PaymentDetail[] = [
    { label: 'Transaction ID', value: transaction_id || 'NA' },
    { label: 'Name', value: name || 'NA' },
    { label: 'Payment Reference', value: payment_reference || 'NA' },
    { label: 'Payment Type', value: payment_type || 'NA' },
  ];

  const handleShare = async (pdfBlobUrl:any) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Invoice Details',
          text: 'Here are your payment details for the transaction.',
          url: pdfBlobUrl, // Use the Blob URL here
        });
        console.log('Share successful');
      } catch (error) {
        console.log('Error sharing:', error);
      }
    } else {
      alert('Share not supported on this device.');
    }
  };
  
  const generateAndShareInvoice = () => {
    const doc = new html2pdf();
    doc.text("Invoice Details", 10, 10);
    doc.text(`Amount: $${amount}`, 10, 20);
    doc.text(`Transaction ID: ${transaction_id}`, 10, 30);
  
    // Convert PDF to Blob
    const pdfBlob = doc.output("blob");
  
    // Create a Blob URL for the generated PDF
    const pdfBlobUrl = URL.createObjectURL(pdfBlob);
  
    // Now share the Blob URL
    handleShare(pdfBlobUrl);
  };
  

  return (
    <div className="flex flex-col">
      <div className="flex flex-col justify-center items-center gap-3 px-6 pt-6">
        <div>
          <img src={success} alt="success-img" className="w-auto h-auto object-contain" />
        </div>
        <div className="flex flex-col items-center gap-1 pb-5">
          <div className="text-[14px] font-normal leading-[21px]">You Paid</div>
          <div className="text-[24px] font-bold leading-[28px]">Paid ${amount}</div>
          <div className="text-[14px] font-normal leading-[21px]">{payment_timestamp}</div>
        </div>
      </div>

      <div className="flex flex-col gap-[16px] bg-[#F5F5F5] p-6">
        <div className="flex flex-col">
          {paymentDetails.map((detail, index) => (
            <div className="payment-details-container-list" key={index}>
              <div className="font-normal text-[14px] leading-[21px]">{detail.label}</div>
              <div className="font-normal text-[14px] leading-[21px]">{detail.value}</div>
            </div>
          ))}
        </div>
        <div className="flex flex-row justify-around">
          <div className="flex flex-row px-2 py-[6px]" onClick={generateAndShareInvoice}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.75718 11.7022C7.19894 12.5613 6.23113 13.13 5.13024 13.13C3.40247 13.13 2 11.7274 2 9.99973C2 8.27207 3.40258 6.86949 5.13024 6.86949C6.23108 6.86949 7.1989 7.43817 7.75718 8.29729L13.9491 5.20177C13.8691 4.96091 13.8256 4.70265 13.8256 4.43483C13.8256 3.09051 14.916 2 16.2605 2C17.6049 2 18.6953 3.0904 18.6953 4.43483C18.6953 5.77927 17.6049 6.86967 16.2605 6.86967C15.4327 6.86967 14.7005 6.45576 14.2605 5.82361L8.06859 8.92002C8.19293 9.25653 8.26076 9.61999 8.26076 10C8.26076 10.38 8.19293 10.7435 8.06859 11.08L14.2605 14.1764C14.7005 13.5442 15.4327 13.1303 16.2605 13.1303C17.6048 13.1303 18.6953 14.2207 18.6953 15.5652C18.6953 16.9096 17.6049 18 16.2605 18C14.916 18 13.8256 16.9096 13.8256 15.5652C13.8256 15.2974 13.8691 15.0391 13.9491 14.7982L7.75718 11.7022ZM7.30851 8.91096C6.90851 8.11273 6.08245 7.5649 5.13029 7.5649C3.78597 7.5649 2.69546 8.6553 2.69546 9.99973C2.69546 11.3442 3.78586 12.4346 5.13029 12.4346C6.08243 12.4346 6.90851 11.8868 7.30851 11.0885C7.47285 10.7615 7.56501 10.3911 7.56501 9.99984C7.56501 9.60855 7.47284 9.23876 7.30851 8.91096ZM14.7049 5.21196C14.9901 5.78238 15.5806 6.17368 16.2606 6.17368C17.2205 6.17368 17.9996 5.39456 17.9996 4.43459C17.9996 3.47461 17.2205 2.6955 16.2606 2.6955C15.3006 2.6955 14.5215 3.47461 14.5215 4.43459C14.5215 4.71458 14.5876 4.97892 14.7049 5.21196ZM14.7049 14.7875C14.5876 15.0214 14.5215 15.2857 14.5215 15.5648C14.5215 16.5248 15.3006 17.3039 16.2606 17.3039C17.2205 17.3039 17.9996 16.5248 17.9996 15.5648C17.9996 14.6049 17.2205 13.8257 16.2606 13.8257C15.5806 13.8257 14.9902 14.217 14.7049 14.7875Z" fill="black" />
            </svg>
            <button className="ml-2 font-medium text-[14px] leading-[21px]">Share Invoice</button>
          </div>
          <div className="flex flex-row px-2 py-[6px] cursor-pointer" onClick={handleDownload}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.3129 2.00021C10.1571 2.00021 10.0081 2.06209 9.89813 2.17208C9.78813 2.28208 9.72626 2.43103 9.72626 2.58687V13.1165L5.80546 9.71454C5.68744 9.61256 5.53391 9.561 5.37809 9.57131C5.22227 9.58161 5.08181 9.65241 5.02292 9.80156C4.96402 9.95071 5.00825 10.1143 5.08594 10.2238L9.22592 14.2238C9.27834 14.3095 9.35888 14.3703 9.45456 14.3703C9.55023 14.3703 9.63077 14.3095 9.68319 14.2238L13.8232 10.2238C13.9009 10.1143 13.9451 9.95071 13.8862 9.80156C13.8273 9.65241 13.6868 9.581 13.5309 9.57131C13.3751 9.561 13.2216 9.61256 13.1036 9.71454L9.18279 13.1165V2.58687C9.18279 2.43103 9.12091 2.28208 9.01091 2.17208C8.90091 2.06209 8.75193 2.00021 8.59611 2.00021H10.3129Z" fill="black" />
            </svg>
            <button className="ml-2 font-medium text-[14px] leading-[21px]">Download Invoice</button>
          </div>
        </div>
        <Footer />
      </div>
      <div style={{ display: 'none' }}>
        <PaymentReceipt
          ref={receiptRef}
          booking_id={booking_id}
          amount={amount}
          transaction_id={transaction_id}
          name={name}
          payment_reference={payment_reference}
          payment_type={payment_type}
          payment_timestamp={payment_timestamp}
        />      
      </div>
    </div>
  );
};

export default Paid;

import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { decodeToken, getTokenFromUrl } from '../utils/tokenUtils';
import { useNavigate } from 'react-router-dom'; 

interface ContextType {
  bookingId: string | null;
  expiresIn: number | null;
  iat: number | null;
}

const GlobalContext = createContext<ContextType | undefined>(undefined);

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [bookingId, setBookingId] = useState<string | null>(null);
  const [expiresIn, setExpiresIn] = useState<number | null>(null);
  const [iat, setIat] = useState<number | null>(null);
   const navigate = useNavigate(); 

  useEffect(() => {
    const token = getTokenFromUrl();
    if (token) {
      (async () => {
        try {
          const decodedData = await decodeToken(token, navigate);
          if (decodedData) {
            setBookingId(decodedData.booking_id);
            setExpiresIn(decodedData.expires_in);
            setIat(decodedData.iat);
          }
        } catch (error) {
          console.error('Error decoding token:', error);
        }
      })();
    }
  }, [navigate]);

  const contextValue = useMemo(() => ({ bookingId, expiresIn, iat }), [bookingId, expiresIn, iat]);

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};

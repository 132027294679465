import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { bottomSheet } from 'react-simple-bottom-sheet';
import Paid from './Paid';
import success from '../assets/success.png';
import failed from '../assets/failed.png';
import timeout from '../assets/timeout.png';
import Button from '../components/common/Button';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import PaymentFailedTimeExp from './PaymentFailedTimeExp';
import PaymentCancellationReason from './PaymentCancellationReason';
import { fetchPaymentSummary, updateStatus } from '../api';
import { useGlobalContext } from '../context/BookingContext';
import { usePaymentStatus } from '../context/PaymentStatusContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { setTimeLeft, decrementTime } from '../store/timerSlice';


const PaymentSummary: React.FC = () => {

  const { bookingId, expiresIn, iat } = useGlobalContext();
  const [isBookingDetailsOpen, setIsBookingDetailsOpen] = useState(true);
  const [paymentData, setPaymentData] = useState<any>(null);
  const [expired, setExpired] = useState(false);
  const dispatch = useDispatch();
  const timeLeft = useSelector((state: RootState) => state.timeLeft);


  const [isLoading, setIsLoading] = useState(false);
  const { paymentStatus, setPaymentStatus } = usePaymentStatus();

  const safeBookingId = bookingId ? parseInt(bookingId) : 0;
  const marginBottom = !expired
    ? (paymentStatus !== 'PAID' && paymentStatus !== 'FAILED' && paymentStatus !== 'CANCELLED' ? 'mb-[102px]' : 'mb-[32px]')
    : 'mb-[32px]';

  useEffect(() => {
    const storedPaymentStatus = localStorage.getItem(`paymentStatus-${safeBookingId}`);
    if (storedPaymentStatus) {
      setPaymentStatus(storedPaymentStatus as 'PAID' | 'FAILED' | 'CANCELLED');
    }

    if (safeBookingId) {
      fetchPaymentSummary(safeBookingId)
        .then((data) => {
          setPaymentData(data.data);
        })
        .catch((error) => {
          console.error('Error fetching payment summary:', error);
          setPaymentData(null);
        });
    }
  }, [safeBookingId]);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(decrementTime());
    }, 1000);

    if (paymentStatus === 'PAID' || paymentStatus === 'CANCELLED' || paymentStatus === 'FAILED') {
      clearInterval(timer);
      dispatch({ type: 'DECREMENT_TIME', payload: 0 });
    }

    return () => clearInterval(timer);
  }, [paymentStatus, dispatch]);



  useEffect(() => {
    if (timeLeft <= 0 && expired && paymentStatus !== 'PAID' && paymentStatus !== 'CANCELLED') {
      setExpired(true);
      openBottomSheet(<PaymentFailedTimeExp payment_timestamp="Today, 10.30 AM" />);
    }
  }, [timeLeft, expired, paymentStatus]);


  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const openBottomSheet = (content: React.ReactNode) =>
    bottomSheet.create({ content });

  const toggleBookingDetails = () => {
    setIsBookingDetailsOpen((prev) => !prev);
  };

  const handlePayNow = async () => {
    if (paymentData && paymentData.status !== 'COMPLETED') {
      const { timestamp } = await updateStatus(safeBookingId, 'COMPLETED', 'PAID', 'DEBIT CARD', 'ref123456789');
      openBottomSheet(
        <Paid
          booking_id={safeBookingId}
          amount={paymentData.fare_breakup.total}
          transaction_id={'ref123456789'}
          name={paymentData.name}
          payment_reference={paymentData.payment_reference}
          payment_type={'DEBIT CARD'}
          payment_timestamp={timestamp}
        />
      );
      console.log('Payment status updated to PAID');
    }
  };

  const handleButtonClick = async () => {
    setIsLoading(true);

    try {
      await handlePayNow();

      const paymentResponse = await checkPaymentStatus();
      if (paymentResponse === 'PAID') {
        setPaymentStatus('PAID');
        localStorage.setItem(`paymentStatus-${safeBookingId}`, 'PAID');
        console.log('Payment status updated to PAID');
      } else {
        setPaymentStatus('FAILED');
        localStorage.setItem(`paymentStatus-${safeBookingId}`, 'FAILED')
      }
    } catch (error) {
      console.error('Payment failed:', error);
      setPaymentStatus('FAILED');
      localStorage.setItem(`paymentStatus-${safeBookingId}`, 'FAILED');
    } finally {
      setIsLoading(false);
    }
  };

  const checkPaymentStatus = async () => {
    return new Promise((resolve) => setTimeout(() => resolve('PAID'), 1000));
  };

  return (
    <div className="flex flex-col w-full">
      <Header timeLeft={formatTimeLeft()} />

      <div className="flex flex-col pt-6 px-6 bg-[white] w-full gap-[20px] screen-size">
        <div className="text-center flex flex-col gap-[4px]">
          {!(paymentStatus === 'PAID' || paymentStatus === 'CANCELLED' || paymentStatus === 'FAILED' || expired) && (
            <>
              <p className="text-base">You are paying</p>
              <p className="text-6xl font-bold text-[#03A9F4]">
                ${paymentData ? paymentData.fare_breakup.total : 'Loading...'}
              </p>
              <p className="text-lg font-bold">Payment Summary</p>
            </>
          )}

          {/* Time Expired Status */}
          {paymentStatus === 'PAID' || expired && paymentStatus === 'CANCELLED' && (
            <div className="flex flex-col gap-3 items-center pt-[8px]">
              <img src={timeout} alt="timeout-img" className="w-[54px] h-[54px] object-contain" />
              <div className="text-[18px] font-medium leading-[21px] text-orange-700">Time Expired</div>
            </div>
          )}

          {/* PAID Status */}
          {paymentStatus === 'PAID' && (
            <div className="flex flex-col gap-3 items-center pt-[8px]">
              <img src={success} alt="success-img" className="w-[54px] h-[54px] object-contain" />
              <div className="text-[18px] font-medium leading-[21px] text-green-700">Payment Completed Successfully</div>
            </div>
          )}

          {/* FAILED Status */}
          {paymentStatus === 'FAILED' && (
            <div className="flex flex-col gap-3 items-center pt-[8px]">
              <img src={failed} alt="failed-img" className="w-[54px] h-[54px] object-contain" />
              <div className="text-[18px] font-medium leading-[21px] text-red-700">Payment Failed</div>
            </div>
          )}

          {/* CANCELLED Status */}
          {paymentStatus === 'CANCELLED' && (
            <div className="flex flex-col gap-3 items-center pt-[8px] text-red-700">
              <img src={failed} alt="cancelled-img" className="w-[54px] h-[54px] object-contain" />
              <div className="text-[18px] font-normal leading-[21px]">Payment Cancelled</div>
            </div>
          )}
        </div>

        {/* Payment Info */}
        {paymentData && (
          <>
            <SummarySection
              title="Payment Details"
              details={[
                { label: 'Name', value: paymentData.name || 'NA' },
                { label: 'Payment Reference', value: paymentData.payment_reference || 'NA' },
              ]}
            />

            {/* Fare Breakup */}
            <SummarySection
              title="Fare Breakup"
              details={[
                { label: 'Trip Fare', value: `$${paymentData.fare_breakup.trip_fare || '0'}` },
                { label: 'Service Charge (Inc)', value: `$${paymentData.fare_breakup.service_charge || '0'}` },
                { label: 'Tax', value: `$${paymentData.fare_breakup.tax || '0'}` },
                { label: 'Total', value: `$${paymentData.fare_breakup.total}`, isBold: true || '0' },
              ]}
            />

            {/* Booking Details */}
            <div className="border border-gray-200 rounded-md pb-2">
              <button
                onClick={toggleBookingDetails}
                className="flex justify-between items-center w-full text-sm font-bold px-3 pt-4"
              >
                Booking Details
                <span
                  className={`transition-transform duration-300 transform ${isBookingDetailsOpen ? 'rotate-180' : ''
                    }`}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12 13.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289Z"
                      fill="black"
                      fillOpacity="0.87"
                    />
                  </svg>
                </span>
              </button>
              {isBookingDetailsOpen && (
                <SummarySection
                  details={[
                    { label: 'Booking ID', value: paymentData.booking_details.booking_id.toString() || 'NA' },
                    { label: 'Booking Date', value: paymentData.booking_details.booking_date || 'NA' },
                    { label: 'Service Type', value: paymentData.booking_details.service_type || 'NA' },
                    { label: 'Distance Covered', value: paymentData.booking_details.distance_covered || 'NA' },
                    { label: 'Pick Up', value: paymentData.booking_details.pickup_location || 'NA' },
                    { label: 'Drop', value: paymentData.booking_details.drop_location || 'NA' },
                  ]}
                  noBorder
                />
              )}
            </div>
          </>
        )}

        {/* Action Buttons */}
        <div className="flex flex-col justify-center text-center gap-2 text-[14px] leading-[24px] font-medium">
          {(!expired && paymentStatus !== 'PAID' && paymentStatus !== 'CANCELLED' && paymentStatus !== 'FAILED') && (
            <button onClick={() => openBottomSheet(<PaymentCancellationReason bookingId={safeBookingId} />)}>
              Cancel Payment
            </button>
          )}
        </div>

        <div className={`${marginBottom}`}>
          <Footer />
        </div>

      </div>

      {!expired && paymentStatus !== 'PAID' && paymentStatus !== 'FAILED' && paymentStatus !== 'CANCELLED' && (
        <div className="bg-white w-full flex flex-col fixed bottom-0 shadow-[0_-4px_8px_rgba(0,0,0,0.1)]">
          <button className="px-[24px] py-[20px]" onClick={handleButtonClick} disabled={isLoading}>
            <div className="flex w-full flex-col px-[22px] py-[8px] rounded text-center cursor-pointer bg-[#2196F3]">
              {isLoading ? (
                <span className="flex justify-center items-center text-[15px] font-medium leading-[26px] text-white">
                  <svg
                    className="animate-spin h-5 w-5 mr-2 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </span>
              ) : (
                <span className="text-[15px] font-medium leading-[26px] text-white">Pay Now</span>
              )}
            </div>
          </button>
        </div>
      )
      }
    </div >
  );
};

interface SummarySectionProps {
  title?: string;
  details: Array<{ label: string; value: string; isBold?: boolean }>;
  noBorder?: boolean;
  bold?: boolean;
}

const SummarySection: React.FC<SummarySectionProps> = ({ title, details, noBorder }) => (
  <div className={`flex flex-col gap-[8px] p-4 rounded-md ${noBorder ? '' : 'border border-gray-200'}`}>
    {title && <p className="text-[14px] font-bold leading-[21px]">{title}</p>}
    {details.map((item, index) => (
      <div key={index} className="flex flex-row justify-between">
        <div className={`text-[14px] leading-[21px] min-w-[50%] ${item.isBold ? 'font-bold' : 'font-normal'}`}>{item.label}</div>
        <div className={`text-[14px] leading-[21px] min-w-[50%] text-end ${item.isBold ? 'font-bold' : 'font-normal'}`}>{item.value}</div>
      </div>
    ))}
  </div>
);

export default PaymentSummary;

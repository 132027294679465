import React, { useEffect, useState } from 'react';
import Footer from '../components/common/Footer';
import Button from '../components/common/Button';
import Loader from '../components/common/Loader';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import { useTranslation } from 'react-i18next';
import { fetchAmountInformation, cancelPayment, generatePaymentLink } from '../api';
import { AmountInformation } from '../api/types';
import { useGlobalContext } from '../context/BookingContext';

const PaymentHomeScreen: React.FC = () => {
    const { bookingId } = useGlobalContext();
    const safeBookingId = bookingId ? parseInt(bookingId) : 0;

    const [loading, setLoading] = useState(true);
    const [amountData, setAmountData] = useState<AmountInformation | null>(null);
    const [isPaymentLinkGenerated, setIsPaymentLinkGenerated] = useState(false);
    const [isPaymentSkipped, setIsPaymentSkipped] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const navigate = useNavigate();
    const { t } = useTranslation();

    // Retrieve data from localStorage on initial load
    useEffect(() => {
        const storedAmountData = localStorage.getItem('amountData');
        const storedPaymentStatus = localStorage.getItem('paymentStatus');

        if (storedAmountData) {
            setAmountData(JSON.parse(storedAmountData));
        }
        if (storedPaymentStatus) {
            const { skipped, generated } = JSON.parse(storedPaymentStatus);
            setIsPaymentSkipped(skipped);
            setIsPaymentLinkGenerated(generated);
        }

        // Fetch amount data if not in localStorage
        if (!storedAmountData && safeBookingId) {
            const loadAmountInformation = async () => {
                try {
                    const data = await fetchAmountInformation(safeBookingId);
                    if (data?.data?.totalAmount) {
                        const amountInfo = {
                            data: {
                                totalAmount: Number(data.data.totalAmount),
                                serviceCharges: Number(data.data.serviceCharges || null),
                                bookingId: safeBookingId,
                            },
                            amount: Number(data.data.totalAmount),
                            currency: 'USD',
                        };
                        setAmountData(amountInfo);
                        localStorage.setItem('amountData', JSON.stringify(amountInfo)); // Save to localStorage
                    }
                } catch (error) {
                    console.error('Error fetching amount information:', error);
                }
            };
            loadAmountInformation();
        }

        const timer = setTimeout(() => setLoading(false), 2000);
        return () => clearTimeout(timer);
    }, [safeBookingId]);

    const handleCancelPayment = async () => {
        try {
            await cancelPayment(safeBookingId);
            setIsPaymentSkipped(true);
            localStorage.setItem('paymentStatus', JSON.stringify({ skipped: true, generated: false }));
        } catch (error) {
            console.error('Error cancelling payment:', error);
        }
    };

    const handleGeneratePaymentLink = async () => {
        setIsProcessing(true);
        try {
            const response = await generatePaymentLink(safeBookingId);
            if (response.status === 200) {
                setIsPaymentLinkGenerated(true);
                localStorage.setItem('paymentStatus', JSON.stringify({ skipped: false, generated: true }));
            }
        } catch (error) {
            console.error('Error generating payment link:', error);
        }
        finally {
            setIsProcessing(false);
        }
    };

    return (
        <div className="flex flex-col w-full min-h-screen">
            <div className="flex flex-row p-5 flex-wrap justify-between bg-[#F5F5F5] min-h-[55px]">
                <img src={logo} alt="Logo" className="w-[140px] h-[22.33px]" />
            </div>
            <div className="px-6 flex flex-col justify-center gap-6 flex-grow">
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="flex flex-col gap-[8px] text-center">
                            <h6 className="text-[18px] font-bold leading-[27px]">{t('welcome')}</h6>
                            <p className="text-[14px] font-normal leading-[21px]">{t('paymentPrompt')}</p>
                        </div>

                        <div className="text-center flex flex-col gap-[4px]">
                            <p className="text-[16px] font-normal leading-[20px]">{t('paymentAmount')}</p>
                            <p className="text-[60px] font-bold text-[#03A9F4]">
                                {amountData?.data?.totalAmount
                                    ? `$${amountData.data.totalAmount.toFixed(2)}`
                                    : 'Loading...'}
                            </p>
                            <p className="text-[14px] leading-[21px] font-normal">
                                Service Charge:{' '}
                                <span className="font-semibold">
                                    {amountData?.data?.serviceCharges
                                        ? `$${amountData.data.serviceCharges.toFixed(2)}`
                                        : '$0.00'}
                                </span>
                            </p>
                        </div>

                        {amountData?.data?.totalAmount && (
                            <>
                                {isPaymentSkipped ? (
                                    <div className="text-center text-[16px] font-medium leading-[24px] text-orange-600">
                                        You have chosen to skip the Lynkpay payment.<br />
                                        The payment will be initiated by the service provider at the end of the journey.<br />
                                        No further action is needed.
                                    </div>
                                ) : isPaymentLinkGenerated ? (
                                    <div className="text-center text-[16px] font-medium leading-[24px] text-green-600">
                                        We've initiated your payment via SMS or email. <br />
                                        Please complete your payment of $
                                        {amountData.data.totalAmount.toFixed(2)}.<br />
                                        Thank you!
                                    </div>
                                ) : (
                                    <div className="flex flex-col text-center gap-[16px] justify-center">
                                        <div className="text-[20px] leading-[24px] font-semibold">Continue?</div>
                                        <div className="flex flex-row gap-[8px]">
                                            <Button
                                                text={t('No')}
                                                onClick={handleCancelPayment}
                                                color="#ffffff"
                                                textColor="#03A9F4"
                                                borderColor="#03A9F4"
                                            />
                                            <Button
                                                text={t('Yes')}
                                                onClick={handleGeneratePaymentLink}
                                                loading={isProcessing}
                                            />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            <div className="px-5 pb-5">
                <Footer />
            </div>
        </div>
    );
};

export default PaymentHomeScreen;

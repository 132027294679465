import React, { createContext, useContext, useState, useEffect } from 'react';

interface PaymentStatusContextType {
  paymentStatus: 'PAID' | 'CANCELLED' | 'FAILED' | null;
  setPaymentStatus: (status: 'PAID' | 'CANCELLED' | 'FAILED' | null) => void;
}

const PaymentStatusContext = createContext<PaymentStatusContextType | undefined>(undefined);

export const PaymentStatusProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [paymentStatus, setPaymentStatus] = useState<'PAID' | 'CANCELLED' | 'FAILED' | null>(null);

  useEffect(() => {
    const storedPaymentStatus = JSON.parse(localStorage.getItem('paymentStatus') || 'null');

    if (storedPaymentStatus) {
      const { value, expiry } = storedPaymentStatus;
      const now = Date.now();

      if (now < expiry) {
        setPaymentStatus(value);
        const timeout = setTimeout(() => {
          localStorage.removeItem('paymentStatus');
          setPaymentStatus(null);
        }, expiry - now);
        return () => clearTimeout(timeout);
      } else {
        localStorage.removeItem('paymentStatus');
      }
    }
  }, []);

  const updatePaymentStatus = (status: 'PAID' | 'CANCELLED' | 'FAILED' | null) => {
    setPaymentStatus(status);

    if (status) {
      const expiry = Date.now() + 15 * 60 * 1000; // 15 minutes in milliseconds
      localStorage.setItem(
        'paymentStatus',
        JSON.stringify({ value: status, expiry })
      );
    } else {
      localStorage.removeItem('paymentStatus');
    }
  };

  return (
    <PaymentStatusContext.Provider value={{ paymentStatus, setPaymentStatus: updatePaymentStatus }}>
      {children}
    </PaymentStatusContext.Provider>
  );
};

export const usePaymentStatus = () => {
  const context = useContext(PaymentStatusContext);
  if (!context) {
    throw new Error('usePaymentStatus must be used within a PaymentStatusProvider');
  }
  return context;
};
